import GtrSuper from "@/modules/common/components/mixins/gtr-super.mixin";
import { Component } from "vue-property-decorator";

@Component({
    name: 'GtrMcProReturn',
})
export default class GtrMcProReturnView extends GtrSuper {

    async mounted() {
        const result = await this.$store.dispatch('event/mcProReturn', this.$route.params.event_identifier, { root: true })
        localStorage.setItem("mcpro_data", JSON.stringify(result.data))
        this.checkURLAndGo(result.data.return_url)
    }

}
